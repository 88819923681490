import styled, { keyframes } from "styled-components";

const loginAnim = keyframes`
100%{
  transform: scale(1);

}
50%{
  transform: scale(0.95);
}
0%{
  transform: scale(1.05);
  

}
`;

export const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
`;

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .background {
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    backdrop-filter: blur(20px);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100vw;
  }

  .login-modal {
    height: fit-content;
    box-sizing: border-box;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 400px;
    animation: ${loginAnim} 0.3s;

    background: #000000;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    .modal-header {
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      background: radial-gradient(
          102.62% 100% at 50% 100%,
          #000000 52.6%,
          rgba(34, 34, 34, 0) 100%
        ),
        linear-gradient(90.18deg, #5c2a1b 0.16%, #270462 100.12%);
      padding: 24px 16px 24px 24px;
      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
      }
      .close-icon {
        cursor: pointer;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 24px 24px 24px;
      gap: 24px;
      width: 100%;
      .box {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        input {
          border: 1px solid #444444;
          padding: 10px 8px;
          background: #323232;
          border-radius: 8px;
          color: white;
          outline-style: none;

          &::placeholder {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #a9a9a9;
          }
        }
        .label {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */

          /* Mono/100 */

          color: #ffffff;
        }
      }
      .box.password .input-wrapper {
        width: 100%;
        display: flex;
        input {
          width: 90%;
          border-radius: 8px 0px 0px 8px;
          border-right: 0px;
        }
        .showpass {
          width: 10%;
          padding: 9px;
          background: #323232;
          border: 1px solid #444444;
          border-left: 0px;
          border-radius: 0px 8px 8px 0px;
          position: relative;
          .showpass-icon {
            color: #767676;
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
          &:before {
            content: "";
            width: 1px;
            background: #444444;
            position: absolute;
            height: 50%;
            left: 0;
          }
        }
      }
    }
    .btn-login {
      text-align: center;
      background: #7e3af2;
      padding: 10px 0px;
      height: 40px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border-radius: 67px;
      width: 100%;
    }
    .forgot-password {
      width: 100%;
      text-align: center;

      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */

      /* gray/gray-500 */

      color: #767676;
    }
    .dont-have-account {
      display: flex;
      width: 100%;
      justify-content: center;
      text-align: center;
      gap: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */

      /* gray/gray-500 */

      color: #767676;
      .sign-up {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */

        /* Purple/500 */

        color: #9061f9;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #444444;
    }
    .twitter {
      background: #323232;
      /* Gray/700 */

      border: 1px solid #444444;
      /* Shadow/Shadow-100 */

      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
      border-radius: 8px;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 100%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      color: #16bdca;
      padding: 12px 0px;
      .twicon {
        width: 20px;
        height: 20px;
        color: #16bdca;
      }
    }
    .facebook {
      background: #323232;
      /* Gray/700 */

      border: 1px solid #444444;
      /* Shadow/Shadow-100 */

      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
      border-radius: 8px;

      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      color: #3f83f8;
      gap: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 12px 0px;
      .fbicon {
        width: 20px;
        height: 20px;
        color: #3f83f8;
      }
    }
  }
`;
