import React from "react";
import MainRoute from "src/modules/MainRoute";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "src/theme";
import { store } from "./store";
import { Provider } from "react-redux";
import { Header, Footer, Connect } from "src/components";
import { UseWalletProvider } from "use-wallet";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import { connectors } from "src/configs/Connect/Configs.rpc";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <UseWalletProvider autoConnect={true} connectors={connectors}>
              <Router>
                <Connect />
                <Header />
                <MainRoute />
                <Footer />
                <ToastContainer />
              </Router>
            </UseWalletProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
