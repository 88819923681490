export const company = [
  {
    title: "TOS",
    href: "/",
  },
  {
    title: "Privacy",
    href: "/",
  },
];
export const comunities = [
  {
    title: "For Artist",
    href: "/",
  },
  {
    title: "For Investor",
    href: "/",
  },
  {
    title: "Advisors",
    href: "/",
  },
  {
    title: "Vendors",
    href: "/",
  },
];

export const info = [
  // {
  //   title: "Disclaimer",
  //   href: "/",
  // },
  {
    title: "Privacy Policy",
    href: "/privacy-policies",
  },
  {
    title: "Terms of use",
    href: "/terms-of-use",
  },
];

export const social = [
  {
    image: "icon-1.png",
    href: "https://www.reddit.com/user/Steplaunch",
  },
  {
    image: "icon-2.png",
    href: "https://admin-260.gitbook.io/step-launch/",
  },
  {
    image: "icon-3.png",
    href: "https://twitter.com/step_launch",
  },
  {
    image: "icon-4.png",
    href: "https://discord.com/invite/PfCuFtMM",
  },
  {
    image: "icon-5.png",
    href: "https://www.tiktok.com/@steplaunch",
  },
  {
    image: "icon-6.png",
    href: "https://admin-260.gitbook.io/step-launch/",
  },
];
