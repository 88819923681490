import styled, { ITheme } from "styled-components";
import ButtonPrimaryBG from "src/assets/home/button_primary.png";
import ButtonNonPrimaryBG from "src/assets/home/button_non_primary.png";
export const ButtonBase = styled.div`
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 600;
  :hover {
    transform: scale(1.02);
  }
  :active {
    transform: scale(0.98);
  }
`;

export const Button = styled(ButtonBase)<{
  type: string;
}>`
  background-image: url(${({ type }) =>
    type === "primary" ? ButtonPrimaryBG : ButtonNonPrimaryBG});

  padding: 14px 60px;
  background-size: 100% 100%;
  color: ${({ type }: any) => (type === "primary" ? "black" : "black")};
  font-size: 16px;
  ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
   font-size:13px;
   padding: 7px 12px;
    `}
`;
