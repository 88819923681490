import styled, { ITheme } from "styled-components";
export const ContainerWrapper = styled.div`
  position: relative;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0px 10px;
  `}
  .content {
    max-width: 1440px;
    width: 100%;
    position: relative;
  }
`;
