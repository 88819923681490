import React from "react";
import AnimationOnScroll from "react-animate-on-scroll";
import { IProps } from "./AnimationWithoutMobile.interface";

export const AnimationWithoutMobile = ({
  children,
  animateIn,
  animateOut,
  delay = 0,
  animateOnce = false,
}: IProps) => {
  if (window.innerWidth >= 1280) {
    return (
      <AnimationOnScroll
        animateOnce={animateOnce}
        animateIn={animateIn}
        animateOut={animateOut}
        delay={delay}
      >
        {children}
      </AnimationOnScroll>
    );
  }
  return children;
};
