import { lazy } from "react";
import { IRouteProps } from "..";

const route = "/terms-of-use";
const Term = lazy(() => import("src/modules/Term-Of-Use/Term"));

const TermOfUseRoute: IRouteProps = {
  path: route,
  component: Term,
  name: "TermOfUse",
  exact: true,
  //   render: (props) => Term<>({ ...props }),
};
export default TermOfUseRoute;
