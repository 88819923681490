import React from "react";
import { LoginContainer, LoginWrapper } from "./Login.styled";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

interface ILoginProps {
  isActive: boolean;
  setIsActive: (params: any) => any;
}
export const Login = ({ isActive, setIsActive }: ILoginProps) => {
  const [isShowPass, setIsShowPass] = React.useState(false);

  return (
    <LoginContainer>
      <LoginWrapper>
        <div
          className="background"
          onClick={() => setIsActive(!isActive)}
        ></div>
        {/* <Container> */}
        <div className="login-modal">
          <div className="modal-header">
            <span>Login</span>
            <CloseIcon
              className="close-icon"
              onClick={() => setIsActive(!isActive)}
            />
          </div>
          <div className="modal-body">
            <div className="box email ">
              <span className="label">Email</span>
              <input type="text" placeholder="Email" />
            </div>
            <div className="box password ">
              <span className="label">Password</span>
              <div className="input-wrapper">
                <input
                  type={isShowPass ? "text" : "password"}
                  placeholder="Password"
                  id="inputpassword"
                />
                <div className="showpass">
                  {isShowPass ? (
                    <VisibilityOffIcon
                      className="showpass-icon"
                      onClick={() => setIsShowPass(!isShowPass)}
                    />
                  ) : (
                    <RemoveRedEyeIcon
                      className="showpass-icon"
                      onClick={() => setIsShowPass(!isShowPass)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="btn-login">Login</div>
            <div className="forgot-password">Forgot your password?</div>
            <div className="dont-have-account">
              <span>Don{"'"}t have an account ?</span>
              <span className="sign-up">Sign up</span>
            </div>
            <div className="line"></div>
            <div className="twitter">
              <TwitterIcon className="twicon" />
              <span>Login with Twitter</span>
            </div>
            <div className="facebook">
              <FacebookIcon className="fbicon" />
              <span>Login with Facebook</span>
            </div>
          </div>
        </div>
        {/* </Container> */}
      </LoginWrapper>
    </LoginContainer>
  );
};

export default Login;
