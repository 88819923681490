import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  background-color: black;

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 50px 0px;
    width: 100%;
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
        padding:40px 0px;
    `}
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
        padding:20px 0px;
    `}
    .logo {
      width: 300px;
      transform: translateY(-30%);
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
         width: 250px; 
         transform: translateY(0%);

        `}
    }
    &-grid {
      display: grid;
      width: 100%;
      grid-template-columns: 1.5fr 1.5fr 1.5fr 1fr;
      grid-gap: 40px;
      margin-left: 80px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
      margin-left: 0px;
      margin-top:40px;
        `}
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
        grid-template-columns: 1fr 1fr;
    `}
      .item {
        display: flex;
        flex-direction: column;
        text-align: start;
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #707070;
        }
        &-link {
          display: flex;
          flex-direction: column;
          a {
            width: fit-content;
            color: white;
            text-decoration: none;
            margin-top: 10px;
            position: relative;
            z-index: 1;
            background-clip: padding-box;
            :hover {
              color: #cf7474;
            }
          }
        }
      }
      .social {
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #707070;
        }
        &-link {
          cursor: pointer;
          margin-top: 10px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }
      }
    }
  }
`;
