import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  .info-top {
    display: flex;
    img {
      width: 180px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .info__name {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        color: #38ff94;
        text-align: start;
      }
      .job__title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        color: #ffffff;
        text-align: start;
      }
    }
  }
  .description {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #ffffff;
    text-align: start;
    margin-top: 20px;
  }
`;
