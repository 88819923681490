import { lazy } from "react";
import { IRouteProps } from "src/modules";

const path = "/profile";

const ProfileRoute: IRouteProps = {
  name: "profile",
  path: path,
  component: lazy(() => import("src/modules/Profile")),
  exact: true,
};
export default ProfileRoute;
