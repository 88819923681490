import { lazy } from "react";
import { IRouteProps } from "..";

export const path = "/login";

export const LoginRoute: IRouteProps = {
  name: "Login",
  component: lazy(() => import("src/modules/Login")),
  path: path,
};
export default LoginRoute;
