import React from "react";
import { Wrapper } from "./Connect.styled";
import { onMoveAnimation } from "src/services/hooks/useDevelopUI";
import withConnect from "src/hoc/withWallet";

const ConnectComponent = ({ connect }: any) => {
  return (
    <Wrapper id="connect">
      <div
        onClick={() => onMoveAnimation("connect", "moveOutOpacity")}
        className="overlay"
      />
      <div className="form-connect">
        <span className="title">Connect Wallet</span>
        <span className="subtitle">
          Sign in with one of available wallet providers orcreate a new wallet.
        </span>
        <div className="wallets">
          <div
            onClick={() =>
              connect().then(() => onMoveAnimation("connect", "moveOutOpacity"))
            }
            className="item"
          >
            <div className="item-icon">
              <img
                src={require("../../assets/wallets/metamask.svg").default}
                alt="Metamask"
              />
            </div>
            <span>Metamask</span>
          </div>
          <div
            onClick={() =>
              connect("walletconnect").then(() =>
                onMoveAnimation("connect", "moveOutOpacity")
              )
            }
            className="item"
          >
            <div className="item-icon">
              <img
                src={require("../../assets/wallets/walletconnect.svg").default}
                alt="Wallet connect"
              />
            </div>
            <span>Wallet Connect</span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export const Connect = withConnect(ConnectComponent);
