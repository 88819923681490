import React from "react";
import { Container } from "src/components";
import { Wrapper } from "./Header.styled";
import { menu } from "./Header.hard";
import { Link } from "react-router-dom";
import { onMoveAnimation } from "src/services/hooks/useDevelopUI";
import { formatAddressToHuman } from "src/services/utils/format";
import withConnect from "src/hoc/withWallet";
import Login from "src/modules/Login";
import { Button } from "src/styles/_base";

const HeaderComponent = ({ account }: any) => {
  console.log(account);
  const [activeLogin, setActiveLogin] = React.useState(false);

  return (
    <>
      <Container>
        <Wrapper>
          <Link to="/">
            <img
              className="logo"
              src={require("src/assets/header/mainlogo.png").default}
              alt="Logo"
            />
          </Link>
          <div className="right-side">
            <ul>
              {menu.map((item, idx) => (
                <li key={idx}>
                  <Link to={item.href}>{item.title}</Link>
                </li>
              ))}
            </ul>
            <div className="connect-container">
              <div className="select-container">
                <select defaultValue={3}>
                  <option>Polygon</option>
                  <option>Binance</option>
                  <option>Ethereum</option>
                </select>
              </div>
              {!account ? (
                <span
                  onClick={() => onMoveAnimation("connect", "moveInOpacity")}
                >
                  Connect Wallet
                </span>
              ) : (
                <span>{formatAddressToHuman(account)}</span>
              )}
            </div>

            <Button
              type="non-primary"
              onClick={() => setActiveLogin(!activeLogin)}
              style={{
                fontWeight: "normal",
                fontSize: "14px",
                marginLeft: "16px",
              }}
            >
              Login
            </Button>
          </div>
        </Wrapper>
      </Container>
      {activeLogin && (
        <Login isActive={activeLogin} setIsActive={setActiveLogin} />
      )}
    </>
  );
};

export const Header = withConnect(HeaderComponent);
