import { lazy } from "react";
import { IRouteProps } from "src/modules";

const route = "/privacy-policies";
const PrivacyRoute: IRouteProps = {
  path: route,
  component: lazy(() => import("src/modules/Privacy-policies/Privacy")),
  name: "Privacy",
  exact: true,
};

export default PrivacyRoute;
