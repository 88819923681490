import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  .form-connect {
    border-radius: 10px;
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 20px;
    .title {
      font-weight: 600;
      font-size: 32px;
    }
    .subtitle {
      font-weight: 400;
      font-size: 16px;
    }
    .wallets {
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #212121;
        margin-top: 15px;
        cursor: pointer;
        &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          img {
            width: 70%;
          }
        }
        span {
          background-color: #212121;
          width: 100%;
          height: 80px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }
`;
