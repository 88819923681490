import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding: 18px 0px;
  z-index: 100;
  width: 100%;
  overflow-x: hidden;
  top: 0;
  left: 0;
  .logo {
    width: 250px;
    position: relative;
    top: 20px;
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
        width:150px;
        top: unset;
    `}
  }
  .right-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        display:none;
    `}
    ul {
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 0;
      list-style: none;
      a {
        font-weight: 400;
        font-size: 14px;
        color: white;
        margin-right: 20px;
        text-decoration: none;
      }
    }
    .connect-container {
      display: flex;
      background: rgba(255, 255, 255, 0.5);
      padding: 6px 12px;
      border-radius: 40px;
      align-items: center;
      column-gap: 8px;
      .select-container {
        border-radius: 20px;
        background-color: black;
        select {
          cursor: pointer;
          border: none;
          background-color: black;
          border-radius: 20px;
          outline-style: none;
          padding: 6px 8px;
          color: white;
          margin-right: 10px;
        }
      }
      span {
        font-weight: 400;
        font-size: 14px;
        color: black;
        cursor: pointer;
      }
    }
    .login {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 97px;
      padding: 10px 12px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* Gray/900 */

      color: #222222;
      cursor: pointer;
      margin-left: 16px;
    }
  }
`;
