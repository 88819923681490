import React from "react";
import { ContainerWrapper } from "./Container.styled";

export const Container = ({
  children,
  id,
}: {
  children: React.ReactNode | React.ReactNode[];
  id?: string;
}) => {
  return (
    <ContainerWrapper id={id}>
      <div className="content">{children}</div>
    </ContainerWrapper>
  );
};
