export const menu = [
  {
    title: "Claim Roles",
    href: "/",
  },
  {
    title: "Sign Up",
    href: "/",
  },
];
