import React from "react";
import { Wrapper } from "./Footer.styled";
import { Container } from "src/components";
import { info, social } from "./Footer.hard";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <div className="content">
          <img
            className="logo"
            src={require("../../assets/footer/mainlogo.png").default}
            alt="Logo"
          />
          <div className="content-grid">
            {/* <div className="item">
              <span className="title">Comunities</span>
              <div className="item-link">
                {comunities.map((item, idx) => (
                  <Link key={idx} to={item.href}>
                    {item.title}
                  </Link>
                ))}
              </div>
            </div> */}
            <div className="item">
              <span className="title">Info</span>
              <div className="item-link">
                {info.map((item, idx) => (
                  <Link key={idx} to={item.href}>
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
            {/* <div className="item">
              <span className="title">Company</span>
              <div className="item-link">
                {company.map((item, idx) => (
                  <Link key={idx} to={item.href}>
                    {item.title}
                  </Link>
                ))}
              </div>
            </div> */}
            <div className="social">
              <span className="title">Social</span>
              <div className="social-link">
                {social.map((item, idx) => (
                  <img
                    src={require(`../../assets/footer/${item.image}`).default}
                    alt="Icon"
                    key={idx}
                    onClick={() => window.open(item.href)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};
